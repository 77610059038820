import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import SEO from "components/SEO"
import PageHero from "components/PageHero"
import ContentBlock from "components/ContentBlock"

const NotFoundPage = props => (
  <Layout location={props.location} navigation>
    {/* {console.log("props, ", props)} */}
    <SEO seo={{ title: "404", siteName: props.data.site.siteMetadata.title }} />
    <PageHero
      details={{
        title: [
          { text: "Pagina", invert: false },
          { text: "niet", invert: true },
          { text: "gevonden", invert: false },
        ],
        subtitle: "404",
        image: {
          src: {
            localFile: props.data.image,
          },
          alt: "",
          title: "",
        },
      }}
    />
    {/* <ContentBlock large title={"Jammer!!!"} content={`<p>eeeeeeeee</p>`} /> */}
  </Layout>
)

export default NotFoundPage

export const pageNotFoundQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    image: file(relativePath: { eq: "404.jpeg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
